import * as React from "react"
import { useRef } from "react"
import Layout from "../components/Layout"
import Container from "../components/Container"
import { graphql, Link } from "gatsby"
import GroupKey from "../components/GroupKey"
import {QRCodeCanvas} from 'qrcode.react';

const AttendeesPage = ({ pageContext, data }) => {

  const { currentPage, numPages } = pageContext

  const isFirst = currentPage === 1

  const isLast = currentPage === numPages

  const prevPage = currentPage - 1 === 1 ? "/attendees" : `/attendees/${currentPage - 1}`

  const nextPage = `/attendees/${currentPage + 1}`

  const canvasRef = useRef();

  const handleButtonClicked = () => {
    const canvas = canvasRef.current.children[0]?.children[0];
    const pngFile = canvas.toDataURL("image/png");

    const downloadLink = document.createElement("a");
    downloadLink.download = "QRCode";
    downloadLink.href = `${pngFile}`;
    downloadLink.click();
  };
    
    return (
        <Layout>
            <Container>
                <h1>Attendees</h1>
                <div className="justify-between md:flex">
                    <GroupKey />
                    <Link className="hover:underline" to="/">← Back to Home</Link>
                </div>
                <ul className="grid gap-8 my-12 md:grid-cols-3 lg:grid-cols-4">
                    {data.allContentfulAttendee.nodes.map(node => (
                        <li className="py-8 text-lg border-b border-gray-300 border-solid" key={node.id}>
                            <div ref={canvasRef}>
                                <div>
                                    <QRCodeCanvas size={150} value={`${process.env.SITE_URL}/${node.slug}`} />
                                </div>
                                <button className="mt-4 hover:text-blue-900" onClick={handleButtonClicked}>Download PNG</button>
                            </div>
                            <Link className="hover:text-blue-900" to={`${process.env.SITE_URL}/${node.slug}`}>
                                <div className="flex items-center mt-8 space-x-4">
                                    <div className={`w-8 h-8 rounded-full ${node.schedule.title === "Schedule A" ? 'bg-green-700' : ''}${node.schedule.title === "Schedule B" ? 'bg-red-700' : ''}${node.schedule.title === "Schedule C" ? 'bg-blue-700' : ''}${node.schedule.title === "Schedule D" ? 'bg-purple-700' : ''}${node.schedule.title === "Schedule E" ? 'bg-orange-700' : ''}`}></div>
                                    <div>
                                        <div className="block font-semibold">{node.name}</div>
                                    </div>
                                </div>
                            </Link>
                        </li>
                    ))}
                </ul>
                <div className="flex items-center justify-between mt-12">
                    {!isFirst && (
                        <Link className="hover:underline" to={prevPage} rel="prev">
                        ← Previous Page
                        </Link>
                    )}
                    {!isLast && (
                        <Link className="hover:underline" to={nextPage} rel="next">
                        Next Page →
                        </Link>
                    )}
                </div>

                {/* <div>

                    <table>
                        <tr>
                            <th>Name</th>
                            <th>QR Code</th>
                        </tr>
                        {data.allContentfulAttendee.nodes.map(node => (
                            <tr>
                                <td>{node.name}</td>
                                <td>URL:https://slaleyhall.pfgl.co.uk/{node.slug}</td>
                            </tr>
                        ))}
                    </table>

                </div> */}
                
            </Container>
        </Layout>
    )
}

export const Query = graphql`
    query allAttendeesQuery($skip: Int!, $limit: Int!) {
        allContentfulAttendee(
            sort: { fields: name, order: ASC }
            limit: $limit
            skip: $skip
        ) {
            nodes {
                id
                name
                slug
                schedule {
                    title
                }
            }
        }
    }
`

export default AttendeesPage