import * as React from "react"

const GroupKey = () => {
    return (
        <div>
            <h4>Group Key</h4>
            <div className="flex items-center space-x-6">
                <div className="flex items-center space-x-2">
                    <div className="w-6 h-6 bg-green-700 rounded-full bg"></div>
                    <span className="text-gray-400">Group 1</span>
                </div>
                <div className="flex items-center space-x-2">
                    <div className="w-6 h-6 bg-red-700 rounded-full bg"></div>
                    <span className="text-gray-400">Group 2</span>
                </div>
                <div className="flex items-center space-x-2">
                    <div className="w-6 h-6 bg-blue-700 rounded-full bg"></div>
                    <span className="text-gray-400">Group 3</span>
                </div>
                <div className="flex items-center space-x-2">
                    <div className="w-6 h-6 bg-purple-700 rounded-full bg"></div>
                    <span className="text-gray-400">Group 4</span>
                </div>
            </div> 
        </div>
    )
}

export default GroupKey